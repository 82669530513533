import { defineComponent, inject } from 'vue';
import Header from '@/components/Header.vue';
import LoginContent from '@/components/LoginContent.vue';
export default defineComponent({
  name: 'Login',
  components: {
    HeaderComponent: Header,
    LoginContent: LoginContent
  },
  setup: function setup() {
    var isMobile = inject('isMobile');
    var t = inject('t');
    return {
      isMobile: isMobile,
      t: t
    };
  }
});